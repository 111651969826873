import { default as _91_46_46_46slug_93pelBYDGXPBMeta } from "/var/www/html/pages/[...slug].vue?macro=true";
import { default as cookie_45declarationZvyoGjbKlKMeta } from "/var/www/html/pages/cookie-declaration.vue?macro=true";
import { default as indexhpT3k8sgsIMeta } from "/var/www/html/pages/horoskoper/index.vue?macro=true";
import { default as fiskPPTqq9CmNUMeta } from "/var/www/html/pages/horoskoper/stjernetegn/fisk.vue?macro=true";
import { default as jomfruXWsLD5K7cUMeta } from "/var/www/html/pages/horoskoper/stjernetegn/jomfru.vue?macro=true";
import { default as krebsZcfz8H4T8JMeta } from "/var/www/html/pages/horoskoper/stjernetegn/krebs.vue?macro=true";
import { default as loevedAbbJ8fEo2Meta } from "/var/www/html/pages/horoskoper/stjernetegn/loeve.vue?macro=true";
import { default as skorpionoAyQt3q5XgMeta } from "/var/www/html/pages/horoskoper/stjernetegn/skorpion.vue?macro=true";
import { default as skytte9hh6KZYRIgMeta } from "/var/www/html/pages/horoskoper/stjernetegn/skytte.vue?macro=true";
import { default as stenbukPSPCXNOyoxMeta } from "/var/www/html/pages/horoskoper/stjernetegn/stenbuk.vue?macro=true";
import { default as tvillingwqEROobSowMeta } from "/var/www/html/pages/horoskoper/stjernetegn/tvilling.vue?macro=true";
import { default as tyr4WrEIFwqGDMeta } from "/var/www/html/pages/horoskoper/stjernetegn/tyr.vue?macro=true";
import { default as vaedderdKYaHwj7JAMeta } from "/var/www/html/pages/horoskoper/stjernetegn/vaedder.vue?macro=true";
import { default as vaegtBOMflRPSorMeta } from "/var/www/html/pages/horoskoper/stjernetegn/vaegt.vue?macro=true";
import { default as vandmandcnvxd1mqPqMeta } from "/var/www/html/pages/horoskoper/stjernetegn/vandmand.vue?macro=true";
import { default as indexUbNeHw0dYYMeta } from "/var/www/html/pages/index.vue?macro=true";
import { default as kontaktqqm8YTGBrSMeta } from "/var/www/html/pages/kontakt.vue?macro=true";
import { default as krydsord1Ugjisua0eMeta } from "/var/www/html/pages/krydsord.vue?macro=true";
import { default as nyhedsbrev_45tilmeldtRr3k4IoXdeMeta } from "/var/www/html/pages/nyhedsbrev-tilmeldt.vue?macro=true";
import { default as nyhedsbrevCgzkpsPOjBMeta } from "/var/www/html/pages/nyhedsbrev.vue?macro=true";
import { default as soegh7TcCV9MM4Meta } from "/var/www/html/pages/soeg.vue?macro=true";
import { default as _91_46_46_46slug_93OhCemqkRl3Meta } from "/var/www/html/pages/video/[...slug].vue?macro=true";
import { default as indexmAHoJoR9JjMeta } from "/var/www/html/pages/video/index.vue?macro=true";
export default [
  {
    name: _91_46_46_46slug_93pelBYDGXPBMeta?.name ?? "slug",
    path: _91_46_46_46slug_93pelBYDGXPBMeta?.path ?? "/:slug(.*)*",
    meta: _91_46_46_46slug_93pelBYDGXPBMeta || {},
    alias: _91_46_46_46slug_93pelBYDGXPBMeta?.alias || [],
    redirect: _91_46_46_46slug_93pelBYDGXPBMeta?.redirect,
    component: () => import("/var/www/html/pages/[...slug].vue").then(m => m.default || m)
  },
  {
    name: cookie_45declarationZvyoGjbKlKMeta?.name ?? "cookie-declaration",
    path: cookie_45declarationZvyoGjbKlKMeta?.path ?? "/cookie-declaration",
    meta: cookie_45declarationZvyoGjbKlKMeta || {},
    alias: cookie_45declarationZvyoGjbKlKMeta?.alias || [],
    redirect: cookie_45declarationZvyoGjbKlKMeta?.redirect,
    component: () => import("/var/www/html/pages/cookie-declaration.vue").then(m => m.default || m)
  },
  {
    name: indexhpT3k8sgsIMeta?.name ?? "horoskoper",
    path: indexhpT3k8sgsIMeta?.path ?? "/horoskoper",
    meta: indexhpT3k8sgsIMeta || {},
    alias: indexhpT3k8sgsIMeta?.alias || [],
    redirect: indexhpT3k8sgsIMeta?.redirect,
    component: () => import("/var/www/html/pages/horoskoper/index.vue").then(m => m.default || m)
  },
  {
    name: fiskPPTqq9CmNUMeta?.name ?? "horoskoper-stjernetegn-fisk",
    path: fiskPPTqq9CmNUMeta?.path ?? "/horoskoper/stjernetegn/fisk",
    meta: fiskPPTqq9CmNUMeta || {},
    alias: fiskPPTqq9CmNUMeta?.alias || [],
    redirect: fiskPPTqq9CmNUMeta?.redirect,
    component: () => import("/var/www/html/pages/horoskoper/stjernetegn/fisk.vue").then(m => m.default || m)
  },
  {
    name: jomfruXWsLD5K7cUMeta?.name ?? "horoskoper-stjernetegn-jomfru",
    path: jomfruXWsLD5K7cUMeta?.path ?? "/horoskoper/stjernetegn/jomfru",
    meta: jomfruXWsLD5K7cUMeta || {},
    alias: jomfruXWsLD5K7cUMeta?.alias || [],
    redirect: jomfruXWsLD5K7cUMeta?.redirect,
    component: () => import("/var/www/html/pages/horoskoper/stjernetegn/jomfru.vue").then(m => m.default || m)
  },
  {
    name: krebsZcfz8H4T8JMeta?.name ?? "horoskoper-stjernetegn-krebs",
    path: krebsZcfz8H4T8JMeta?.path ?? "/horoskoper/stjernetegn/krebs",
    meta: krebsZcfz8H4T8JMeta || {},
    alias: krebsZcfz8H4T8JMeta?.alias || [],
    redirect: krebsZcfz8H4T8JMeta?.redirect,
    component: () => import("/var/www/html/pages/horoskoper/stjernetegn/krebs.vue").then(m => m.default || m)
  },
  {
    name: loevedAbbJ8fEo2Meta?.name ?? "horoskoper-stjernetegn-loeve",
    path: loevedAbbJ8fEo2Meta?.path ?? "/horoskoper/stjernetegn/loeve",
    meta: loevedAbbJ8fEo2Meta || {},
    alias: loevedAbbJ8fEo2Meta?.alias || [],
    redirect: loevedAbbJ8fEo2Meta?.redirect,
    component: () => import("/var/www/html/pages/horoskoper/stjernetegn/loeve.vue").then(m => m.default || m)
  },
  {
    name: skorpionoAyQt3q5XgMeta?.name ?? "horoskoper-stjernetegn-skorpion",
    path: skorpionoAyQt3q5XgMeta?.path ?? "/horoskoper/stjernetegn/skorpion",
    meta: skorpionoAyQt3q5XgMeta || {},
    alias: skorpionoAyQt3q5XgMeta?.alias || [],
    redirect: skorpionoAyQt3q5XgMeta?.redirect,
    component: () => import("/var/www/html/pages/horoskoper/stjernetegn/skorpion.vue").then(m => m.default || m)
  },
  {
    name: skytte9hh6KZYRIgMeta?.name ?? "horoskoper-stjernetegn-skytte",
    path: skytte9hh6KZYRIgMeta?.path ?? "/horoskoper/stjernetegn/skytte",
    meta: skytte9hh6KZYRIgMeta || {},
    alias: skytte9hh6KZYRIgMeta?.alias || [],
    redirect: skytte9hh6KZYRIgMeta?.redirect,
    component: () => import("/var/www/html/pages/horoskoper/stjernetegn/skytte.vue").then(m => m.default || m)
  },
  {
    name: stenbukPSPCXNOyoxMeta?.name ?? "horoskoper-stjernetegn-stenbuk",
    path: stenbukPSPCXNOyoxMeta?.path ?? "/horoskoper/stjernetegn/stenbuk",
    meta: stenbukPSPCXNOyoxMeta || {},
    alias: stenbukPSPCXNOyoxMeta?.alias || [],
    redirect: stenbukPSPCXNOyoxMeta?.redirect,
    component: () => import("/var/www/html/pages/horoskoper/stjernetegn/stenbuk.vue").then(m => m.default || m)
  },
  {
    name: tvillingwqEROobSowMeta?.name ?? "horoskoper-stjernetegn-tvilling",
    path: tvillingwqEROobSowMeta?.path ?? "/horoskoper/stjernetegn/tvilling",
    meta: tvillingwqEROobSowMeta || {},
    alias: tvillingwqEROobSowMeta?.alias || [],
    redirect: tvillingwqEROobSowMeta?.redirect,
    component: () => import("/var/www/html/pages/horoskoper/stjernetegn/tvilling.vue").then(m => m.default || m)
  },
  {
    name: tyr4WrEIFwqGDMeta?.name ?? "horoskoper-stjernetegn-tyr",
    path: tyr4WrEIFwqGDMeta?.path ?? "/horoskoper/stjernetegn/tyr",
    meta: tyr4WrEIFwqGDMeta || {},
    alias: tyr4WrEIFwqGDMeta?.alias || [],
    redirect: tyr4WrEIFwqGDMeta?.redirect,
    component: () => import("/var/www/html/pages/horoskoper/stjernetegn/tyr.vue").then(m => m.default || m)
  },
  {
    name: vaedderdKYaHwj7JAMeta?.name ?? "horoskoper-stjernetegn-vaedder",
    path: vaedderdKYaHwj7JAMeta?.path ?? "/horoskoper/stjernetegn/vaedder",
    meta: vaedderdKYaHwj7JAMeta || {},
    alias: vaedderdKYaHwj7JAMeta?.alias || [],
    redirect: vaedderdKYaHwj7JAMeta?.redirect,
    component: () => import("/var/www/html/pages/horoskoper/stjernetegn/vaedder.vue").then(m => m.default || m)
  },
  {
    name: vaegtBOMflRPSorMeta?.name ?? "horoskoper-stjernetegn-vaegt",
    path: vaegtBOMflRPSorMeta?.path ?? "/horoskoper/stjernetegn/vaegt",
    meta: vaegtBOMflRPSorMeta || {},
    alias: vaegtBOMflRPSorMeta?.alias || [],
    redirect: vaegtBOMflRPSorMeta?.redirect,
    component: () => import("/var/www/html/pages/horoskoper/stjernetegn/vaegt.vue").then(m => m.default || m)
  },
  {
    name: vandmandcnvxd1mqPqMeta?.name ?? "horoskoper-stjernetegn-vandmand",
    path: vandmandcnvxd1mqPqMeta?.path ?? "/horoskoper/stjernetegn/vandmand",
    meta: vandmandcnvxd1mqPqMeta || {},
    alias: vandmandcnvxd1mqPqMeta?.alias || [],
    redirect: vandmandcnvxd1mqPqMeta?.redirect,
    component: () => import("/var/www/html/pages/horoskoper/stjernetegn/vandmand.vue").then(m => m.default || m)
  },
  {
    name: indexUbNeHw0dYYMeta?.name ?? "index",
    path: indexUbNeHw0dYYMeta?.path ?? "/",
    meta: indexUbNeHw0dYYMeta || {},
    alias: indexUbNeHw0dYYMeta?.alias || [],
    redirect: indexUbNeHw0dYYMeta?.redirect,
    component: () => import("/var/www/html/pages/index.vue").then(m => m.default || m)
  },
  {
    name: kontaktqqm8YTGBrSMeta?.name ?? "kontakt",
    path: kontaktqqm8YTGBrSMeta?.path ?? "/kontakt",
    meta: kontaktqqm8YTGBrSMeta || {},
    alias: kontaktqqm8YTGBrSMeta?.alias || [],
    redirect: kontaktqqm8YTGBrSMeta?.redirect,
    component: () => import("/var/www/html/pages/kontakt.vue").then(m => m.default || m)
  },
  {
    name: krydsord1Ugjisua0eMeta?.name ?? "krydsord",
    path: krydsord1Ugjisua0eMeta?.path ?? "/krydsord",
    meta: krydsord1Ugjisua0eMeta || {},
    alias: krydsord1Ugjisua0eMeta?.alias || [],
    redirect: krydsord1Ugjisua0eMeta?.redirect,
    component: () => import("/var/www/html/pages/krydsord.vue").then(m => m.default || m)
  },
  {
    name: nyhedsbrev_45tilmeldtRr3k4IoXdeMeta?.name ?? "nyhedsbrev-tilmeldt",
    path: nyhedsbrev_45tilmeldtRr3k4IoXdeMeta?.path ?? "/nyhedsbrev-tilmeldt",
    meta: nyhedsbrev_45tilmeldtRr3k4IoXdeMeta || {},
    alias: nyhedsbrev_45tilmeldtRr3k4IoXdeMeta?.alias || [],
    redirect: nyhedsbrev_45tilmeldtRr3k4IoXdeMeta?.redirect,
    component: () => import("/var/www/html/pages/nyhedsbrev-tilmeldt.vue").then(m => m.default || m)
  },
  {
    name: nyhedsbrevCgzkpsPOjBMeta?.name ?? "nyhedsbrev",
    path: nyhedsbrevCgzkpsPOjBMeta?.path ?? "/nyhedsbrev",
    meta: nyhedsbrevCgzkpsPOjBMeta || {},
    alias: nyhedsbrevCgzkpsPOjBMeta?.alias || [],
    redirect: nyhedsbrevCgzkpsPOjBMeta?.redirect,
    component: () => import("/var/www/html/pages/nyhedsbrev.vue").then(m => m.default || m)
  },
  {
    name: soegh7TcCV9MM4Meta?.name ?? "soeg",
    path: soegh7TcCV9MM4Meta?.path ?? "/soeg",
    meta: soegh7TcCV9MM4Meta || {},
    alias: soegh7TcCV9MM4Meta?.alias || [],
    redirect: soegh7TcCV9MM4Meta?.redirect,
    component: () => import("/var/www/html/pages/soeg.vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_93OhCemqkRl3Meta?.name ?? "video-slug",
    path: _91_46_46_46slug_93OhCemqkRl3Meta?.path ?? "/video/:slug(.*)*",
    meta: _91_46_46_46slug_93OhCemqkRl3Meta || {},
    alias: _91_46_46_46slug_93OhCemqkRl3Meta?.alias || [],
    redirect: _91_46_46_46slug_93OhCemqkRl3Meta?.redirect,
    component: () => import("/var/www/html/pages/video/[...slug].vue").then(m => m.default || m)
  },
  {
    name: indexmAHoJoR9JjMeta?.name ?? "video",
    path: indexmAHoJoR9JjMeta?.path ?? "/video",
    meta: indexmAHoJoR9JjMeta || {},
    alias: indexmAHoJoR9JjMeta?.alias || [],
    redirect: indexmAHoJoR9JjMeta?.redirect,
    component: () => import("/var/www/html/pages/video/index.vue").then(m => m.default || m)
  }
]