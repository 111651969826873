<template>
  <div v-if="burgerActive" class="absolute top-full z-40 w-full pt-10 overflow-hidden flex flex-col" :style="{ 'max-height': `${burgerMaxHeight}px` }">
    <WidthWrapper class="flex flex-col h-full w-full" wrapper-classes="max-w-960 mx-auto">
      <div class="pointer-events-auto min-w-full sm-md:min-w-[414px] max-w-[414px] ml-auto towers:-mr-[200px] flex-0 overflow-y-auto" :style="{ 'max-height': `${burgerMaxHeight}px` }">
        <SearchForm id="globalSearch" @search="closeBurger" class="bg-blue-200 px-10 py-15" />
        <nav class="bg-white">
          <ul>
            <template v-for="(item, index) in menu">
              <li class="">
                <SiteMenuItem :sub-item="item" @closeBurger="closeBurger(item)" />
                <DottedLine class="mx-15 pt-2" v-if="index !== menu.length - 1" />
              </li>
            </template>

            <template v-for="(item, index) in menuSecondary">
              <li class="bg-gray-100">
                <SiteMenuItem :sub-item="item" @closeBurger="closeBurger(item)" />
                <DottedLine class="mx-15 pt-2" v-if="index !== menuSecondary.length - 1" />
              </li>
            </template>

            <template v-for="(item, index) in menuTertiary">
              <li class="bg-blue-400">
                <SiteMenuItem a-class="text-white" :sub-item="item" @closeBurger="closeBurger(item)" />
                <DottedLine class="mx-15 pt-2" color="#ffffff" v-if="index !== menuTertiary.length - 1" />
              </li>
            </template>
          </ul>
        </nav>
      </div>
    </WidthWrapper>
  </div>
</template>

<script setup lang="ts">
defineProps({
  burgerMaxHeight: {
    type: Number,
  },
  burgerActive: {
    type: Boolean,
  },
});

const { public: config } = useRuntimeConfig();

const emit = defineEmits(['close']);
const route = useRoute();

/**
 * Emit close signal, to close the burger menu.
 *
 * @returns void
 */
const closeBurger = (item: Aller.MenuItem | null = null) => {
  if (item) {
    window.scrollTo({ top: 0, behavior: route.fullPath === item.slug ? 'smooth' : 'auto' });
  }

  emit('close');
};

/**
 * The menus loaded from api.
 *
 * Provides the states globally in the application trough useState
 */
const { data }: { data: any } = await useFetch(`https://${config.dataApiUrl}/api/v1/menu`, {
  onRequestError({ request, options, error }) {
    console.debug('MenuBurgerRequestError - request', request);
    console.debug('MenuBurgerRequestError - options', options);
    console.debug('MenuBurgerRequestError - error', error);
  },
  onResponseError({ request, response, options }) {
    console.debug('MenuBurgerResponseError - request', request);
    console.debug('MenuBurgerResponseError - response', response);
    console.debug('MenuBurgerResponseError - options', options);
  },
});

const menu = useState('menu', () => data.value.primary || []);
const menuSecondary = useState('menuSecondary', () => data.value.secondary || []);
const menuTertiary = useState('menuTertiary', () => data.value.tertiary || []);

/**
 * Currently 'active' menu items'.
 *
 * @type {Ref<UnwrapRef<number[]>>}
 */
const activeMenuIndexes = ref([] as number[]);

/**
 * Determine if a given index is in the activeMenuIndexes.
 *
 * @param {number} index
 * @returns {boolean}
 */
const activeMenuIndex = (index: number) => {
  return !!activeMenuIndexes.value.includes(index);
};

/**
 * Toggle if an index in the menu should be 'active'.
 *
 * @param {number} index
 */
const toggleMenuIndex = (index: number) => {
  activeMenuIndex(index)
    ? (activeMenuIndexes.value = activeMenuIndexes.value.filter((value) => {
      return value !== index;
    }))
    : activeMenuIndexes.value.push(index);
};
</script>
