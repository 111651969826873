<template>
  <header class="sticky top-[-1px] z-50 pointer-events-none" id="site-header">
    <div class="absolute top-0 inset-x-0 pt-10" ref="siteHeader">
      <WidthWrapper class="flex justify-end" wrapper-classes="max-w-960 mx-auto px-10 lg:px-20">
        <div class="bubble bg-red-400 flex items-center py-5 lg:py-6 pl-[17px] lg:pl-[22px] pr-10 rounded-[25px] lg:rounded-[40px] rounded-bl-none lg:rounded-bl-none relative z-40 transition-ease duration-300" :class="{ 'lg:pl-[17px]': scrolled || route.fullPath !== '/' }">
          <NuxtLink class="block pointer-events-auto" to="/" @click.native="scrollTop" aria-label="Gå til forsiden">
            <i class="bg-svg-logo-white bg-center bg-no-repeat w-[67px] h-[40px] block transition-ease duration-300" :class="scrolled || route.fullPath !== '/' ? 'lg:w-[67px] lg:h-[40px]' : 'lg:w-[87px] lg:h-[53px]'"></i>
          </NuxtLink>
          <i class="bg-white pl-1 py-20 ml-7 mr-2"></i>
          <button class="flex flex-col square-44 justify-center p-5 pointer-events-auto" @click="toggleActiveBurger" aria-label="Åben menu">
            <span class="pt-px pb-3 bg-white my-3 w-full transition-transform duration-125 origin-top-left" :class="{ 'rotate-45 -translate-y-7 translate-x-5': burgerActive }"></span>
            <span class="pt-px pb-3 bg-white my-3 w-full" :class="{ hidden: burgerActive }"></span>
            <span class="pt-px pb-3 bg-white my-3 w-full transition-transform duration-125 origin-bottom-left" :class="{ '-rotate-45 translate-y-7 translate-x-5': burgerActive }"></span>
          </button>
        </div>
      </WidthWrapper>
      <transition name="fade">
        <siteMenuBurger :burgerActive="burgerActive" :burgerMaxHeight="burgerMaxHeight" @close="toggleActiveBurger" />
      </transition>
      <transition name="blur">
        <div ref="blurryLayer" v-if="burgerActive" @click="toggleActiveBurger" class="pointer-events-auto h-screen backdrop-filter backdrop-blur-sm w-screen absolute top-0 left-0 right-0 z-[30]"></div>
      </transition>
    </div>
  </header>
</template>

<script setup lang="ts">
const route = useRoute();

/**
 * If the burger is shown or not.
 */
const burgerActive = ref(false);

/**
 * The maximum height, the burgermenu can consume.
 */
const burgerMaxHeight = ref(0);

/**
 * Template reference to the dom element.
 */
const siteHeader = ref<HTMLDivElement>();

/**
 * Template reference to the dom element.
 */
const blurryLayer = ref<HTMLDivElement>();

/**
 * Detect scrolls
 */
const scrolled = ref(false);

let observer: ResizeObserver | null = null;

/**
 * Recalculates the max height of the burger menu.
 *
 * @returns void
 */
const recalculateBurgerMaxHeight = () => {
  if (siteHeader && siteHeader.value) {
    const top = siteHeader.value.getBoundingClientRect().top;
    burgerMaxHeight.value = window.innerHeight - ((top > 0 ? 0 : top) + siteHeader.value.getBoundingClientRect().height);
  }
};

/**
 * Toggles the SiteMenuBurger active state and sets the max height.
 *
 * @returns void
 */
const toggleActiveBurger = () => {
  burgerActive.value = !burgerActive.value;
  recalculateBurgerMaxHeight();
};

/**
 * Scroll past the top banner.
 *
 * @returns void
 */
const scrollPastTopBannerIfPresent = () => {
  const nuxtEl = document.getElementById('__nuxt');
  if (!nuxtEl) {
    return;
  }

  // Only scroll if the nuxt element is below the top banner.
  if (nuxtEl.offsetTop > 0 && window.scrollY < nuxtEl.offsetTop) {
    window.scrollTo({ top: nuxtEl.offsetTop, behavior: 'smooth' });
    recalculateBurgerMaxHeight();
  }
};

/**
 * Scroll past top banner if present. if burger menu is active
 */
watch(burgerActive, (value) => {
  if (value) {
    scrollPastTopBannerIfPresent();
  }
});

onMounted(() => {
  // Detect scrolled.
  if (siteHeader.value) {
    observer = new IntersectionObserver(([e]) => {
      scrolled.value = e.intersectionRatio < 1;
    }, { threshold: [1] });
    observer.observe(siteHeader.value);
  }
});

/**
 * Scroll top, frontpage will animate the scroll.
 *
 * @returns void
 */
const scrollTop = () => {
  window.scrollTo({ top: 0, behavior: route.fullPath === '/' ? 'smooth' : 'auto' });
  if (burgerActive.value === true) {
    burgerActive.value = false;
  }
};
</script>

<style lang="css" scoped>
.fade-enter-active {
  transition: all 0.2s cubic-bezier(0.55, 0.085, 0.68, 0.53);
}

.fade-leave-active {
  transition: all 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94);
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.blur-enter-active {
  transition: opacity 0.2s cubic-bezier(0.55, 0.085, 0.68, 0.53);
}

.blur-leave-active {
  transition: opacity 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94);
}

.blur-enter-from,
.blur-leave-to {
  opacity: 0;
}
</style>
