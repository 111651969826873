<template>
  <footer data-towers-stop="true">
    <section class="bg-blue-400 pt-20 pb-25">
      <WidthWrapper wrapper-classes="max-w-960 mx-auto px-10">
        <div class="flex items-center justify-center flex-wrap">
          <h2 class="w-full font-heebo font-bold text-14 leading-19 tracking-[0.84px] uppercase mb-20 text-center text-white md:text-base md:font-bold md:leading-21 md:tracking-[0.96px]">Følg os her</h2>
          <NuxtLink to="/video" :class="someLinkClass" aria-label="Video">
            <i class="bg-svg-some-youtube-white hover:bg-svg-some-youtube-red-400" :class="someIconClass" />
          </NuxtLink>
          <a href="https://www.facebook.com/billedbladet/?refsrc=http%3A%2F%2Fwww.billedbladet.dk%2F" :class="someLinkClass" aria-label="Følg os på Facebook">
            <i class="bg-svg-some-facebook-white hover:bg-svg-some-facebook-red-400" :class="someIconClass" />
          </a>
          <a href="https://www.instagram.com/billedbladet/" :class="someLinkClass" aria-label="Følg os på Instagram">
            <i class="bg-svg-some-instagram-white hover:bg-svg-some-instagram-red-400" :class="someIconClass" />
          </a>
        </div>
      </WidthWrapper>
    </section>
    <div class="bg-white text-14 leading-22 font-light tracking-[0.84px] pt-20 pb-20">
      <WidthWrapper wrapper-classes="max-w-960 mx-auto px-10">
        <div class="flex flex-wrap justify-center mb-25">
          <div>Udgiver: <strong class="font-bold">Aller Media</strong></div>
          <span class="hidden md:block" :class="divider">|</span>
          <div>Mail: <a class="font-bold text-red-400 hover:text-blue-400 underline" href="mailto:redaktionen@billedbladet.dk">redaktionen@billedbladet.dk</a></div>
        </div>
        <div class="flex flex-wrap justify-center mb-20 md:mb-15">
          <div>Adresse: Havneholmen 33<span :class="divider">|</span>1561 København V</div>
          <span class="hidden md:block" :class="divider">|</span>
          <div>CVR.: 43325612<span :class="divider">|</span>Tlf.: 72 34 20 00</div>
        </div>
        <DottedLine class="md:hidden mb-10" />
        <div class="flex flex-wrap justify-center items-center">
          <a :class="lowerFooterLinkClass" href="/kontakt">Kontakt</a>
          <span :class="divider">|</span>
          <a :class="lowerFooterLinkClass" href="https://www.allerservice.dk/brands/billed-bladet?utm_source=billedbladet.dk&utm_medium=owned&utm_campaign=aller_billedbladet_ao_conversion_abo&utm_term=footerlink">Køb abonnement</a>
          <span :class="divider">|</span>
          <a :class="lowerFooterLinkClass" href="http://www.aller.dk/mediesalg/download-materialer">Annoncør</a>
          <span class="hidden md:block" :class="divider">|</span>
          <div class="w-full md:w-auto" />
          <a :class="lowerFooterLinkClass" href="https://aller.dk/privatlivspolitik/">Privatliv</a>
          <span :class="divider">|</span>
          <a :class="lowerFooterLinkClass" href="https://aller.dk/ophavsret/">Ophavsret</a>
          <span :class="divider">|</span>
          <button :class="lowerFooterLinkClass" @click="showCookiePrompt">Administrer samtykke</button>
        </div>
      </WidthWrapper>
    </div>
  </footer>
</template>

<script setup lang="ts">
const someLinkClass = 'square-44 flex items-center justify-center mx-25';
const someIconClass = 'square-30 bg-center bg-contain bg-no-repeat';
const divider = 'font-semibold mx-10';
const lowerFooterLinkClass = 'leading-30 text-red-400 font-bold hover:text-blue-400 inline-block py-10 underline';
const { showCookiePrompt } = useAllerCookiebot();
</script>
