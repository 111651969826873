<template>
  <div class="flex justify-center" :class="outerClasses">
    <form class="w-full bg-white flex items-center pl-20 rounded-l-20 rounded-r-20" :class="formClasses" action="soeg" method="get" @submit.prevent="search()">
      <div class="search-input">
        <input type="search" :id="id" v-model="searchTerm" placeholder=" " />
        <label :for="id">Skriv søgeord</label>
      </div>
      <button type="submit" aria-label="Søg" class="ml-5 flex items-center justify-center bg-blue-400 w-[57px] h-[37px] rounded-r-[20px] hover:bg-red-400">
        <i class="square-20 bg-center bg-no-repeat bg-svg-search-white"></i>
      </button>
    </form>
  </div>
</template>

<script setup lang="ts">
const props = defineProps({
  id: {
    type: String,
    default: 'search',
  },
  outerClasses: {
    type: String,
    default: 'w-full',
  },
  formClasses: {
    type: String,
    default: 'mx-10',
  },
  searchTermPrefill: {
    type: String,
    default: ''
  },
});

const emit = defineEmits(['search']);
const router = useRouter();

/**
 * Current search term.
 */
const searchTerm = ref(props.searchTermPrefill);

/**
 * Executes search form.
 *
 * @returns void
 */
const search = () => {
  emit('search');
  router.push('/soeg?q=' + searchTerm.value);
};
</script>

<style lang="css" scoped>
.search-input {
  @apply relative flex-grow;
}

.search-input input {
  @apply w-full h-full pr-10 font-semibold;
}

.search-input input::-webkit-search-cancel-button {
  -webkit-appearance: none;
}

.search-input input:focus {
  @apply outline-none;
}

.search-input input + label {
  @apply leading-20 absolute top-0 left-0 italic text-base mt-3 transition-all duration-200 ease-linear;
}

.search-input input:not(:placeholder-shown) + label,
.search-input input:focus + label {
  @apply -mx-5 px-5 -mt-20 bg-white not-italic rounded-md text-12;
}
</style>
