<template>
  <div id="app-root">
    <SiteHtml />
    <NuxtLoadingIndicator color="repeating-linear-gradient(to right,#0E2557 0%,#EC028C 50%,#E40D2B 100%)" />
    <AllerAd class="mb-40" :key="route.fullPath" :ad="{ slotId: 'dsk_responsive_1' }" v-show="!isNative" />
    <AllerAd class="mb-40" :key="route.fullPath" :ad="{ slotId: 'mob_responsive_1' }" v-show="!isNative" />
    <SiteHeader />
    <NuxtPage />
    <SiteFooter />
  </div>
</template>
<script setup lang="ts">
// Inject globalApp. cookiebot, tcf etc.
useAllerGlobalApp();

const route = useRoute();

// Get native state telling us if this is a native page or not.
const isNative = useState('native', () => true);

// Inject additional scripts.
useConsentRequiredScripts();
useAllerAdTechScript();
</script>
