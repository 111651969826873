export default defineAppConfig({
  adTech: {
    targeting: {
      betting: 'no',
    },
    placements: {
      default: {
        sizes: [[1, 1]],
        targeting: [{ pos: ['default'] }],
      },
      dsk_top_ad: {
        sizes: [[1, 2]],
        targeting: [
          {
            pos: ['top_ad'],
          },
          {
            format: ['topscroll_dsk'],
          },
        ],
      },
      dsk_responsive_1: {
        sizes: [
          [930, 180],
          [728, 90],
          [970, 270],
          [970, 170],
          [1, 2],
        ],
        targeting: [
          {
            pos: ['responsive_1'],
          },
          {
            format: ['skin', 'topscroll_dsk'],
          },
        ],
      },
      dsk_responsive_1_skn: {
        sizes: [
          [728, 90],
          [300, 250],
          [930, 180],
          [930, 600],
          [970, 270],
        ],
        targeting: [
          {
            pos: ['responsive_1_skn'],
          },
        ],
      },
      dsk_responsive_2: {
        sizes: [
          [728, 90],
          [300, 250],
          [930, 180],
          [930, 600],
          [970, 270],
        ],
        targeting: [
          {
            pos: ['responsive_2'],
          },
        ],
      },
      dsk_responsive_3: {
        sizes: [
          [728, 90],
          [300, 250],
          [930, 180],
          [930, 600],
          [970, 270],
          [970, 570],
          [970, 550],
        ],
        targeting: [
          {
            pos: ['responsive_3'],
          },
          {
            format: ['interscroll_dsk', 'double_interscroll_dsk'],
          },
        ],
      },
      dsk_responsive_4: {
        sizes: [
          [728, 90],
          [300, 250],
          [930, 180],
          [930, 600],
          [970, 270],
        ],
        targeting: [
          {
            pos: ['responsive_4'],
          },
        ],
      },
      dsk_responsive_5: {
        sizes: [
          [728, 90],
          [300, 250],
          [930, 180],
          [930, 600],
          [970, 270],
          [970, 570],
        ],
        targeting: [
          {
            pos: ['responsive_5'],
          },
          {
            format: ['interscroll_dsk'],
          },
        ],
      },
      dsk_responsive_6: {
        sizes: [
          [728, 90],
          [300, 250],
          [930, 180],
          [930, 600],
          [970, 270],
        ],
        targeting: [
          {
            pos: ['responsive_6'],
          },
        ],
      },
      dsk_responsive_7: {
        sizes: [
          [728, 90],
          [300, 250],
          [930, 180],
          [930, 600],
          [970, 270],
          [970, 570],
        ],
        targeting: [
          {
            pos: ['responsive_7'],
          },
          {
            format: ['interscroll_dsk'],
          },
        ],
      },
      dsk_responsive_8: {
        sizes: [
          [728, 90],
          [300, 250],
          [930, 180],
          [930, 600],
          [970, 270],
        ],
        targeting: [
          {
            pos: ['responsive_8'],
          },
        ],
      },
      dsk_responsive_9: {
        sizes: [
          [728, 90],
          [300, 250],
          [930, 180],
          [930, 600],
          [970, 270],
          [970, 570],
        ],
        targeting: [
          {
            pos: ['responsive_9'],
          },
          {
            format: ['interscroll_dsk'],
          },
        ],
      },
      dsk_responsive_10: {
        sizes: [
          [728, 90],
          [300, 250],
          [930, 180],
          [930, 600],
          [970, 270],
        ],
        targeting: [
          {
            pos: ['responsive_10'],
          },
        ],
      },
      dsk_intext_ad_bottom: {
        sizes: [
          [728, 90],
          [300, 250],
          [930, 180],
          [930, 600],
          [970, 270],
          [970, 570],
          [970, 550],
        ],
        targeting: [
          {
            pos: ['intext_ad_bottom'],
          },
          {
            format: ['interscroll_dsk', 'double_interscroll_dsk'],
          },
        ],
      },
      dsk_intext_ad_article_bottom: {
        sizes: [
          [728, 90],
          [300, 250],
          [930, 180],
          [930, 600],
          [970, 270],
          [970, 570],
        ],
        targeting: [
          {
            pos: ['intext_ad_article_bottom'],
          },
          {
            format: ['interscroll_dsk'],
          },
        ],
      },
      dsk_rec_ad_1: {
        sizes: [
          [300, 250],
          [300, 600],
        ],
        targeting: [
          {
            pos: ['rec_ad_1'],
          },
        ],
      },
      dsk_rec_ad_2: {
        sizes: [
          [300, 250],
          [300, 600],
        ],
        targeting: [
          {
            pos: ['rec_ad_2'],
          },
        ],
      },
      dsk_rec_ad_3: {
        sizes: [
          [300, 250],
          [300, 600],
        ],
        targeting: [
          {
            pos: ['rec_ad_3'],
          },
        ],
      },
      dsk_intext_ad_1: {
        sizes: [
          [300, 250],
          [300, 600],
        ],
        targeting: [
          {
            pos: ['intext_ad_1'],
          },
        ],
      },
      dsk_intext_ad_2: {
        sizes: [
          [300, 250],
          [300, 600],
        ],
        targeting: [
          {
            pos: ['intext_ad_2'],
          },
        ],
      },
      dsk_intext_ad_3: {
        sizes: [
          [300, 250],
          [300, 600],
        ],
        targeting: [
          {
            pos: ['intext_ad_3'],
          },
        ],
      },
      dsk_intext_ad_4: {
        sizes: [
          [300, 250],
          [300, 600],
        ],
        targeting: [
          {
            pos: ['intext_ad_4'],
          },
        ],
      },
      dsk_intext_ad_5: {
        sizes: [
          [300, 250],
          [300, 600],
        ],
        targeting: [
          {
            pos: ['intext_ad_5'],
          },
        ],
      },
      dsk_intext_ad_6: {
        sizes: [
          [300, 250],
          [300, 600],
        ],
        targeting: [
          {
            pos: ['intext_ad_6'],
          },
        ],
      },
      dsk_intext_ad_7: {
        sizes: [
          [300, 250],
          [300, 600],
        ],
        targeting: [
          {
            pos: ['intext_ad_7'],
          },
        ],
      },
      dsk_intext_ad_8: {
        sizes: [
          [300, 250],
          [300, 600],
        ],
        targeting: [
          {
            pos: ['intext_ad_8'],
          },
        ],
      },
      dsk_intext_ad_9: {
        sizes: [
          [300, 250],
          [300, 600],
        ],
        targeting: [
          {
            pos: ['intext_ad_9'],
          },
        ],
      },
      dsk_rec_ad_4: {
        sizes: [
          [300, 250],
          [300, 600],
        ],
        targeting: [
          {
            pos: ['rec_ad_4'],
          },
        ],
      },
      dsk_rec_ad_5: {
        sizes: [
          [300, 250],
          [300, 600],
        ],
        targeting: [
          {
            pos: ['rec_ad_5'],
          },
        ],
      },
      dsk_rec_ad_6: {
        sizes: [
          [300, 250],
          [300, 600],
        ],
        targeting: [
          {
            pos: ['rec_ad_6'],
          },
        ],
      },
      'dsk_rec_ad_7></div>': {
        sizes: [
          [300, 250],
          [300, 600],
        ],
        targeting: [
          {
            pos: ['rec_ad_7'],
          },
        ],
      },
      dsk_intext_ad_sticky1: {
        sizes: [[300, 250]],
        targeting: [
          {
            pos: ['intext_ad_sticky1'],
          },
        ],
      },
      dsk_intext_ad_sticky2: {
        sizes: [[300, 250]],
        targeting: [
          {
            pos: ['intext_ad_sticky2'],
          },
        ],
      },
      dsk_intext_ad_sticky3: {
        sizes: [[300, 250]],
        targeting: [
          {
            pos: ['intext_ad_sticky3'],
          },
        ],
      },
      dsk_intext_ad_sticky4: {
        sizes: [[300, 250]],
        targeting: [
          {
            pos: ['intext_ad_sticky4'],
          },
        ],
      },
      dsk_intext_ad_sticky5: {
        sizes: [[300, 250]],
        targeting: [
          {
            pos: ['intext_ad_sticky5'],
          },
        ],
      },
      dsk_sky1: {
        sizes: [
          [160, 600],
          [300, 600],
        ],
        targeting: [
          {
            pos: ['sky1'],
          },
        ],
      },
      dsk_sky2: {
        sizes: [
          [160, 600],
          [300, 600],
        ],
        targeting: [
          {
            pos: ['sky2'],
          },
        ],
      },
      dsk_intext_ad_gallery: {
        sizes: [[300, 250]],
        targeting: [
          {
            pos: ['intext_ad_gallery'],
          },
        ],
      },
      nat_dsk_responsive: {
        sizes: [
          [728, 90],
          [300, 250],
          [930, 180],
          [930, 600],
          [970, 570],
          [970, 560],
        ],
        targeting: [
          {
            pos: ['nat-responsive'],
          },
        ],
      },
      dsk_rec_liveblog: {
        sizes: [[300, 250]],
        targeting: [
          {
            pos: ['rec_liveblog'],
          },
        ],
      },
      mob_top_ad: {
        sizes: [
          [1, 2],
          [300, 220],
        ],
        targeting: [
          {
            pos: ['top_ad'],
          },
          {
            format: ['topscroll_mob'],
          },
        ],
      },
      mob_responsive_1: {
        sizes: [
          [320, 160],
          [300, 250],
          [320, 320],
          [300, 600],
        ],
        targeting: [
          {
            pos: ['responsive_1'],
          },
          {
            format: ['topscroll_mob'],
          },
        ],
      },
      mob_responsive_2: {
        sizes: [
          [320, 160],
          [300, 250],
          [320, 320],
          [300, 600],
          [300, 240],
        ],
        targeting: [
          {
            pos: ['responsive_2'],
          },
          {
            format: ['interscroll_mob'],
          },
        ],
      },
      mob_intext_ad_1: {
        sizes: [
          [320, 160],
          [300, 250],
          [320, 320],
          [300, 600],
          [300, 240],
          [300, 210],
        ],
        targeting: [
          {
            pos: ['intext_ad_1'],
          },
          {
            format: ['interscroll_mob', 'double_interscroll_mob'],
          },
        ],
      },
      mob_intext_ad_2: {
        sizes: [
          [320, 160],
          [300, 250],
          [320, 320],
          [300, 600],
          [300, 240],
        ],
        targeting: [
          {
            pos: ['intext_ad_2'],
          },
          {
            format: ['interscroll_mob'],
          },
        ],
      },
      mob_intext_ad_3: {
        sizes: [
          [320, 160],
          [300, 250],
          [320, 320],
          [300, 600],
          [300, 240],
        ],
        targeting: [
          {
            pos: ['intext_ad_3'],
          },
          {
            format: ['interscroll_mob'],
          },
        ],
      },
      mob_intext_ad_4: {
        sizes: [
          [320, 160],
          [300, 250],
          [320, 320],
          [300, 600],
          [300, 240],
          [300, 210],
        ],
        targeting: [
          {
            pos: ['intext_ad_4'],
          },
          {
            format: ['interscroll_mob', 'double_interscroll_mob'],
          },
        ],
      },
      mob_intext_ad_5: {
        sizes: [
          [320, 160],
          [300, 250],
          [320, 320],
          [300, 600],
          [300, 240],
        ],
        targeting: [
          {
            pos: ['intext_ad_5'],
          },
          {
            format: ['interscroll_mob'],
          },
        ],
      },
      mob_intext_ad_6: {
        sizes: [
          [320, 160],
          [300, 250],
          [320, 320],
          [300, 600],
          [300, 240],
        ],
        targeting: [
          {
            pos: ['intext_ad_6'],
          },
          {
            format: ['interscroll_mob'],
          },
        ],
      },
      mob_intext_ad_7: {
        sizes: [
          [320, 160],
          [300, 250],
          [320, 320],
          [300, 600],
          [300, 240],
        ],
        targeting: [
          {
            pos: ['intext_ad_7'],
          },
          {
            format: ['interscroll_mob'],
          },
        ],
      },
      mob_intext_ad_8: {
        sizes: [
          [320, 160],
          [300, 250],
          [320, 320],
          [300, 600],
          [300, 240],
          [300, 210],
        ],
        targeting: [
          {
            pos: ['intext_ad_8'],
          },
          {
            format: ['interscroll_mob', 'double_interscroll_mob'],
          },
        ],
      },
      mob_intext_ad_9: {
        sizes: [
          [320, 160],
          [300, 250],
          [320, 320],
          [300, 600],
          [300, 240],
        ],
        targeting: [
          {
            pos: ['intext_ad_9'],
          },
          {
            format: ['interscroll_mob'],
          },
        ],
      },
      mob_responsive_3: {
        sizes: [
          [320, 160],
          [300, 250],
          [320, 320],
          [300, 600],
          [300, 240],
          [300, 210],
        ],
        targeting: [
          {
            pos: ['responsive_3'],
          },
          {
            format: ['interscroll_mob', 'double_interscroll_mob'],
          },
        ],
      },
      mob_responsive_4: {
        sizes: [
          [320, 160],
          [300, 250],
          [320, 320],
          [300, 600],
          [300, 240],
        ],
        targeting: [
          {
            pos: ['responsive_4'],
          },
          {
            format: ['interscroll_mob'],
          },
        ],
      },
      mob_responsive_5: {
        sizes: [
          [320, 160],
          [300, 250],
          [320, 320],
          [300, 600],
          [300, 240],
        ],
        targeting: [
          {
            pos: ['responsive_5'],
          },
          {
            format: ['interscroll_mob'],
          },
        ],
      },
      mob_responsive_6: {
        sizes: [
          [320, 160],
          [300, 250],
          [320, 320],
          [300, 600],
          [300, 240],
          [300, 210],
        ],
        targeting: [
          {
            pos: ['responsive_6'],
          },
          {
            format: ['interscroll_mob', 'double_interscroll_mob'],
          },
        ],
      },
      mob_responsive_7: {
        sizes: [
          [320, 160],
          [300, 250],
          [320, 320],
          [300, 600],
          [300, 240],
        ],
        targeting: [
          {
            pos: ['responsive_7'],
          },
          {
            format: ['interscroll_mob'],
          },
        ],
      },
      mob_responsive_8: {
        sizes: [
          [320, 160],
          [300, 250],
          [320, 320],
          [300, 600],
          [300, 240],
        ],
        targeting: [
          {
            pos: ['responsive_8'],
          },
          {
            format: ['interscroll_mob'],
          },
        ],
      },
      mob_responsive_9: {
        sizes: [
          [320, 160],
          [300, 250],
          [320, 320],
          [300, 600],
          [300, 240],
          [300, 210],
        ],
        targeting: [
          {
            pos: ['responsive_9'],
          },
          {
            format: ['interscroll_mob', 'double_interscroll_mob'],
          },
        ],
      },
      mob_responsive_10: {
        sizes: [
          [320, 160],
          [300, 250],
          [320, 320],
          [300, 600],
          [300, 240],
        ],
        targeting: [
          {
            pos: ['responsive_10'],
          },
          {
            format: ['interscroll_mob'],
          },
        ],
      },
      mob_intext_ad_bottom: {
        sizes: [
          [320, 160],
          [300, 250],
          [320, 320],
          [300, 600],
          [300, 240],
        ],
        targeting: [
          {
            pos: ['intext_ad_bottom'],
          },
          {
            format: ['interscroll_mob'],
          },
        ],
      },
      mob_intext_ad_article_bottom: {
        sizes: [
          [320, 160],
          [300, 250],
          [320, 320],
          [300, 600],
          [300, 240],
        ],
        targeting: [
          {
            pos: ['intext_ad_article_bottom'],
          },
          {
            format: ['interscroll_mob'],
          },
        ],
      },
      mob_nat_responsive: {
        sizes: [
          [320, 160],
          [300, 250],
          [320, 320],
          [300, 600],
          [300, 230],
          [300, 240],
        ],
        targeting: [
          {
            pos: ['nat-responsive'],
          },
        ],
      },
      mob_liveblog: {
        sizes: [
          [320, 160],
          [300, 250],
          [320, 320],
        ],
        targeting: [
          {
            pos: ['mob_liveblog'],
          },
        ],
      },
    },
  },
});
