<template>
  <div class="bg-repeat-x bg-bottom bg-[length:10px_10px] dotted-line pt-px" :style="gradient" />
</template>

<script setup lang="ts">
const props = defineProps({
  color: {
    type: String,
    default: '#000000'
  },
});

const gradient = `background-image: linear-gradient(to right, ${props.color} 10%, rgba(255, 255, 255, 0) 0%);`;
</script>
