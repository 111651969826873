<template>
  <div id="error">
    <SiteHtml />
    <AllerAd class="mb-40" :ad="{ slotId: 'dsk_responsive_1', routeUpdate: true }" />
    <AllerAd class="mb-40" :ad="{ slotId: 'mob_responsive_1', routeUpdate: true }" />
    <SiteHeader />
    <TowerAds />
    <main class="relative z-10">
      <WidthWrapper class="pt-85 px-10 lg:px-20 pb-15 md:pt-90 md:pb-25">
        <PageTitle small :title="error?.statusCode === 404 ? 'Siden blev ikke fundet' : 'Øv, der skete en fejl'" class="mx-auto max-w-710 px-20 mb-20 text-center" />
        <p class="mx-auto text-20 px-15 text-center pb-45 md:px-35 lg:text-18 lg:w-600 lg:px-0">
          Beklager, men vi kunne ikke finde den side, du er på udkig efter.<br />
          Tjek venligst, at du har angivet webadressen korrekt.<br />
          Prøv vores forside eller fortæl os om fejlen.<br />
          Du kan også vælge at søge efter det, du leder efter ...
        </p>
        <div class="-mx-10">
          <SearchForm class="bg-blue-200 px-10 py-15" />
        </div>
        <div class="dotted-line mx-15 mt-45 mb-15 md:mx-auto pt-px md:w-600 lg:w-700"></div>
        <AllerAd :ad="{ slotId: 'mob_responsive_7' }" class="mb-20" />
        <AllerAd :ad="{ slotId: 'dsk_responsive_7' }" class="mb-20" />
      </WidthWrapper>
    </main>
    <SiteFooter />
  </div>
</template>

<script setup lang="ts">
import type { NuxtError } from '#app';

const props = defineProps({
  error: Object as () => NuxtError,
});

// Meta tags
useAllerMetaData().errorMeta(props.error);

// Inject globalApp. cookiebot, tcf etc.
useAllerGlobalApp();

// Inject additional scripts.
useConsentRequiredScripts();
useAllerAdTechScript();
</script>
