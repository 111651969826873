<template>
  <NuxtLink v-if="subItem.slug.indexOf('https://') < 0" class="block font-heebo font-bold text-26 leading-36 tracking-[0.26px] py-15 px-30 uppercase" :class="aClass" :to="subItem.slug" v-on:click.native="closeBurger()">{{ subItem.title }}</NuxtLink>
  <a v-else target="_blank" rel="noreferrer" class="block font-heebo font-bold text-26 leading-36 tracking-[0.26px] py-15 px-30 uppercase" :class="aClass" :href="subItem.slug">{{ subItem.title }}</a>
</template>

<script setup lang="ts">
defineProps({
  subItem: {
    type: Object as PropType<Aller.MenuItem>,
    required: true,
  },
  aClass: {
    type: String,
  }
});

const emit = defineEmits<{ (e: 'close-burger'): void }>();

const closeBurger = () => {
  emit('close-burger');
}
</script>
